<template> 
    <div class="page-content header-clear-small" v-if="!loading">
        <div class="card card-style">
            <div class="content">
				<div class="d-flex">
                    <div class="mr-auto"><span class="badge bg-green-dark-sdw text-white p-2 text-uppercase rounded-s mb-3"><a class="text-white" :href="'/commune/'+post.entity.id">{{post.entity.name}}</a></span></div>
					<div class="mr-auto"><span class="badge bg-red-dark-sdw p-2 text-white text-uppercase rounded-s mb-3"><a class="text-white" :href="'/commune/'+post.entity.id">{{post.postcategory.name}}</a></span></div>
                    <div class="mx-auto"><span class="opacity-50 font-10"><i class="far fa-clock pr-2"></i>{{ post.created_at | moment('DD/MM/YYYY hh:mm:ss')}}</span></div>
                </div>
				
                <img  v-if="post.image_url" :src="post.image_url" class="img-fluid rounded-s shadow-m" style="width:100%">
                <h4 class="bolder mt-4">{{post.name}}</h4>
                <p class="mt-4"><strong>{{post.introduction}}</strong></p>
				
				<p>{{post.description}}</p>	
				
				<div class="d-flex pb-4">		    
                        <span class="color-theme font-11 opacity-50">Publié par <strong>{{post.entity.name}}</strong> le {{ post.created_at | moment('DD/MM/YYYY')}} sur <a href="https://ypodo.com">Ypodo</a>  dans la catégorie <strong> {{post.postcategory.name}}</strong></span>
                    
				</div>
                <div class="d-flex">
                    <a href="#" @click.prevent="shareToFacebook" class="mx-auto icon icon-xs rounded-sm bg-facebook"><i class="fab fa-facebook-f"></i></a>
                    <a href="#" @click.prevent="shareToTwitter" class="mx-auto  icon icon-xs rounded-sm bg-twitter"><i class="fab fa-twitter"></i></a>
                    <a href="#" @click.prevent="shareToWhatsApp" class="mx-auto icon icon-xs rounded-sm bg-whatsapp"><i class="fab fa-whatsapp"></i></a>
                    <a href="#" @click.prevent="shareToLinkedIn(post.name)" class="mx-auto  icon icon-xs rounded-sm bg-linkedin"><i class="fab fa-linkedin-in"></i></a>
                    <a href="#" @click.prevent="shareToMail" class="mx-auto icon icon-xs rounded-sm bg-mail"><i class="fa fa-envelope"></i></a>
                </div>				
            </div>
        </div>
		<!-- sec 2-->
		<div class="card card-style" v-if="related && (related.length > 0)">
            <div class="content mb-4">
                <h3>{{post.entity.name}}</h3>
                <p>
                    D'autres articles qui pourraient vous intéresser
                </p>
                <a :href="'/article/'+post.id" v-for="(post, index) in related" v-bind:key="post.id">
                    <div class="d-flex mb-3">
                        <div class="align-self-center mr-auto">
                            <div  v-if="post.image_url" class="rounded-m mr-3" style="background-size: cover; height:120px; width:120px;  background-position: center;" v-bind:style="{ 'background-image': 'url(' + post.image_url + ')' }">
						</div>
						<div  v-else class="rounded-m mr-3" style="background-size: cover; height:120px; width:120px;" v-bind:style="{ 'background-image': 'url(' + post.entity.background_url + ')' }">
						</div>	
                            <span class="badge text-uppercase px-2 py-1 d-block mr-3 mt-n3 rounded-m under-slider-btn" v-bind:class="classObject(index)">{{post.postcategory.name}}</span>
                        </div>
                        <div class="align-self-center w-100">
                            <h5 class="font-15 pt-2">{{truncte(post.name,80, '...')}}</h5>
                            <span class="color-theme font-11 opacity-50">{{ post.created_at | moment('DD/MM/YYYY')}}</span>
                        </div>
                    </div>
					<div class="divider mb-3"></div>
                </a>
            </div>
        </div>
		
		
		<!-- sec 2 end -->
		
		
          
    </div>
    <!-- End of Page Content--> 
</template>

<script>

import AppConstants from '@/config'
export default {
  name: 'Article',
  data() {
    return{
	post:{},
	related: [],
	colors:[
	'bg-blue2-dark',
	'bg-green1-dark',
	'bg-yellow1-dark',
	'bg-teal-dark', 
	'bg-brown1-dark', 
	'bg-dark1-light',
	'bg-facebook',
	'bg-red2-dark',
	'bg-magenta2-dark',
	'bg-magenta1-dark',
	'bg-red2-dark',
	'bg-gray2-dark', 
	'bg-plum-dark',
	'bg-orange-dark'],
	loading: true
	}
  },
  components: {
    Footer
  },
  props: {
    id: {
      type: [String, Number],
      default: 0
    },
    isloggedin: {
      type: Number,
      default: 5
    }	
  },   
  mounted() {
      this.getPost()
  },  
  methods: {
	shareToFacebook(){
		var share_link = window.location.href
		var def = "https://www.facebook.com/sharer/sharer.php?u="+share_link
		window.open(def, '_blank')
	},
	shareToTwitter(){
		var share_link = window.location.href
		var def = "https://twitter.com/home?status="+share_link
		window.open(def, '_blank')
	},
	shareToWhatsApp(){
		var share_link = window.location.href
		var def = "whatsapp://send?text=" + share_link
		window.open(def, '_blank')
	},
	shareToLinkedIn(title){
		var share_link = window.location.href
		var share_title = title
		var def = "https://www.linkedin.com/shareArticle?mini=true&url="+share_link+"&title="+share_title+"&summary=&source="
		window.open(def, '_blank')
	},
	shareToMail(){
			var share_link = window.location.href
			var def = "mailto:?body=" + share_link
			window.open(def, '_blank')

	},
/*  
        $('.shareToPinterest').prop("href", "https://pinterest.com/pin/create/button/?url=" + share_link)
*/ 
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	truncte (text, stop, clamp) {
		return text.slice(0, stop) + (stop < text.length ? clamp || '...' : '')
	},
	classObject(index){
				return this.colors[index]		
	},	
	getPost() {
				this.loading = true
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-post"				
				
                if(this.id != 0) {
                    var apiData = { 
					"post_id": this.id,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.post = response.data.data
						if ((response.data.related) && (response.data.related.length > 0)) this.related = response.data.related
						this.loading = false
					}
				})
					
				} 
	}
  }
}


</script>
